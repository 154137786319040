<template>
  <v-app>
    <v-layout class="app-layout">
      <v-app-bar-nav-icon class="menu-icon" aria-label="Menu" @click.stop="drawer = !drawer" color="black" ></v-app-bar-nav-icon>
      
      <v-navigation-drawer v-model="drawer" location="left" temporary class="menu-drawer">
      <v-list-item
        v-for="(item, i) in itemsMenu"
        :key="i"
        :value="item.value"
        color="primary"
        @click="goToPath(item.path)"
      >
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>

        <v-list-item-title v-text="item.text"></v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
      <div class="lang-container">
        <LanguageSelector></LanguageSelector>
      </div>
      <div class="header-container">
        <HeaderComponent
          :scrolled="headerScrolled"
          :headerChange="headerChange"
          :headerCarousel="headerCarousel"
          @isModalImage="handleModalImage"
        ></HeaderComponent>
      </div>

      <div class="content-container" @scroll="handleRouterViewScroll">
        <router-view
          @changeHeight="onChangeHeight"
          @isCarouselHeader="onCarouselHeader"
        ></router-view>
      </div>

      <FooterComponent></FooterComponent>
      <DialogComponentVue
        :currentImage="this.currentImage.src"
        :isModal="isModal"
        @close="isModal = false"
      ></DialogComponentVue>
      <NotificationComponent :key="$i18n.locale" />
    </v-layout>
  </v-app>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import DialogComponentVue from "./components/DialogComponent.vue";
import LanguageSelector from "./components/LanguageSelector.vue";
import { iniciatePWA } from "./services/serviceEndpoints.js"
import { terminatePWA } from "./services/serviceEndpoints.js";
import NotificationComponent from './components/NotificationComponent.vue'
import { getFestivals } from '@/services/festivals'
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    DialogComponentVue,
    LanguageSelector,
    NotificationComponent
  },
  data: () => ({
    headerScrolled: false,
    headerChange: false,
    headerCarousel: false,
    isModal: false,
    currentImage: "",
    drawer: false,
    showFestivalSection: true,
    festivalHref: ''
  }),
  computed: {
    shouldHideLinks() {
      return this.$route.query.hideLinks === 'yes';
    },
    itemsMenu() {
      const params = new URLSearchParams(window.location.search);
      const langParam = params.get('language');
      const items = (!this.shouldHideLinks) ? [
        {
          text: this.$t('pages.beaches'),
          icon: "mdi-beach",
          path: `/info/beaches`,
          value: 1
        },
        {
          text: this.$t('pages.agenda'),
          icon: "mdi-notebook",
          path: "/info/agenda",
          value: 2
        },
        {
          text: this.$t('pages.activities'),
          icon: "mdi-ticket",
          path: "/info/activities",
          value: 3
        },
        {
          text: this.$t('pages.festivals'),
          icon: "mdi-party-popper",
          path: this.festivalHref,
          value: 5
        },
        {
          text: this.$t('pages.culture'),
          icon: "mdi-palette",
          path: "/info/culture",
          value: 4
        },
        {
          text: this.$t('pages.what-to-do'),
          icon: "mdi-head-question",
          path: "https://tickets.lloretdemar.org/ca",
          value: 6
        },
        {
          text: this.$t('pages.tourism-offices'),
          icon: "mdi-office-building",
          path: "https://lloretdemar.org/planifica-el-teu-viatge/oficines-de-turisme/",
          value: 7
        },
        {
          text: this.$t('pages.contact-form'),
          icon: "mdi-account-box",
          path: "https://lloretdemar.org/contacte/",
          value: 8
        },
      ] : [
        {
          text: this.$t('pages.beaches'),
          icon: "mdi-beach",
          path: `/info/beaches?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
          value: 1
        },
        {
          text: this.$t('pages.agenda'),
          icon: "mdi-notebook",
          path: `/info/agenda?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
          value: 2
        },
        {
          text: this.$t('pages.activities'),
          icon: "mdi-ticket",
          path: `/info/activities?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
          value: 3
        },
        {
          text: this.$t('pages.culture'),
          icon: "mdi-palette",
          path: `/info/culture?hideLinks=yes${langParam ? `&language=${langParam}`: ''}`,
          value: 4
        }
      ]
      return this.showFestivalSection ? items : items.filter(item => item.value !== 5);
    }
  },
  created() {
    localStorage.setItem("date", new Date().getTime())

    if(localStorage.getItem("lang") == null){
      localStorage.setItem("lang",'ca')
    }
    this.$i18n.locale = localStorage.getItem("lang");
    localStorage.setItem("page", 0)
    this.updateTitle(this.$route);
    this.getFestivalsData();
  },
  watch: {
		'$route'(to) {
      this.updateTitle(to);
    },
    '$i18n.locale'() {
      this.getFestivalsData();
    }
	},
  mounted() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
    this.onIniciate();
  },
  methods: {
    onBeforeUnload() {
      terminatePWA()
    },
    onIniciate() {
      iniciatePWA()
    },
    goToPath(route) {
      if (route.startsWith('http')) {
        window.open(route, '_blank');
      } else {
        this.$router.push(route);
      }
    },
    handleRouterViewScroll(event) {
      if (!this.headerChange) {
        const scrollTop = event.target.scrollTop;
        this.headerScrolled = scrollTop > 0;
      }
    },
    onChangeHeight(event, event2) {
      this.headerChange = event;
      this.headerScrolled = event2;
    },
    onCarouselHeader(event) {
      this.headerCarousel = event;
    },
    handleModalImage(event, image) {
      this.isModal = event;
      this.currentImage = image;
    },
    capitalizeWords(str) {
      return str.replace(/-/g, ' ').replace(/\b\w/g, function(char) {
        return char.toUpperCase();
      });
    },
    updateTitle(to){
      const pageName = to.path.split('/');
      const currentPageTitle = to.params.desc ? this.capitalizeWords(to.params.desc) : pageName[pageName.length - 1].length !== 0 ? this.$t(`pages.${pageName[pageName.length - 1]}`) : '';
      if (to.name) {
        document.title = to.path !== '/' ? `${currentPageTitle} - Lloret de Mar` : 'Lloret de Mar';
      } else {
        document.title = this.$t("pages.404.title");
      }
    },
    getFestivalsData(){
      getFestivals(this.$i18n.locale)
        .then((result) => {
          if (result.data[0] === undefined){
            this.showFestivalSection = false;
            return
          }
          const festivalLink = result.data[0].field_poster;
          const hrefRegex = /href="(.*?)"/;
          const match = festivalLink.match(hrefRegex);
          this.festivalHref = `https://cms.lloret.cat${match[1]}`;
        });
    }
  },
};
</script>

<style>
a{
  text-decoration: none;
  color: initial;
}
.term-of-use-view .share-bar .icon-container{
  display: none;
}
.v-label{
  color: #060606;
}
.loading-info--loading{
  margin-bottom: 50px;
}
.menu-icon {
  position: absolute;
  top: -10px;
  left: 0px;
  z-index: 100;
}
.lang-container {
  position: absolute;
  top:12px;
  left: 90px;
  z-index: 2; 
  width: 70px;
}
.menu-drawer {
  width: 300px !important;
}

.view-container__content {
    height: 90%;
    width: 95%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 40px;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, auto);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.v-layout {
  flex-direction: column;
}

.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-container {
  position: relative; /* Ensure .header-container also acts as the positioning context */
  z-index: 1; /* Set a lower z-index value */
}

.content-container {
  flex-grow: 1;
  /*overflow-y: auto;*/
}

.content-container-small {
  left: 0;
  right: 0;
  overflow-y: auto;
  height: calc(100% - 160px - 60px);
}

@media (max-width: 600px) {
  .view-container__content{
    grid-template-columns: repeat(1, 1fr);
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .desc-text img{
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
  .desc-text ul {
    padding: 0 15px;
  }
  .content-container{
    overflow-y: initial;
  }
}
@media (max-width: 770px) {
  .view-container__filter {
    width: 100% !important;
    display: block !important;
  }
  .view-container__filter .field-search{
    min-width: 100% !important;
    margin-left: 0 !important;
    padding: 0 15px;
  }
  .view-container__filter .button-filter, .view-container__filter .button-reset{
    width: 46%;
  }
}
@media (max-width: 450px) {
    .v-pagination button{
        width: 33px !important;
        height: 33px !important;
    }
}
</style>
