<template>
    <div class="card-component">
        <div v-if="getImage" class="card-component__image"
            :style="{ backgroundImage: `url(${getImage})` }" />
        <div v-else class="card-component__no_image"
            :style="{ backgroundImage: `../assets/no-image.jpg` }" />
        <div class="card-component__content">
            <h3 class="card-component__content--title">
                {{getTitle}}
            </h3>
            <div class="card-component__content--description">
                <div v-html="getDescription"></div>
            </div>
            <br>
            <div class="card-component__content--category">
                <div v-if="pathName == 'beaches'">
                    <div class="card-component__content--date">
                        {{ $t("cards.dates") }}: {{getDateInfoOpen}} - {{getDateInfoClose}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.services") }}: {{getServices}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.length") }}: {{getLenght}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.width") }}: {{getWidth}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.sand") }}: {{getSand}}
                    </div>
                </div>
                <div v-else-if="pathName == 'agenda'">
                    <div class="card-component__content--date">
                        {{ $t("cards.dates") }}: {{getDateInfoOpen}} - {{getDateInfoClose}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.price") }}: {{getPrice}}
                    </div>
                    <div class="card-component__content--date">
                        {{ $t("cards.address") }}: {{getLocationAddress}}
                    </div>
                </div>
            </div>
        </div>
        <div class="more-icon" @click="goToCardLink()">
            <v-icon>mdi mdi-chevron-right</v-icon>
        </div>
    </div>
</template>
<script>
import { transformTitleToLink, setCharacterLimit } from "../utils/utils";
export default {
    name: "HorizontalCard",
    props: {
        cardData: {
            type: Object,
            require: true,
            default() {
                return {
                }
            }
        },
        cardImage: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            api: "https://cms.lloret.cat",
            pathName: ""
        }
    },
    created() {
        this.pathName = this.$route.path.split("/info/")[1];
    },
    methods: {
        goToCardLink() {
            const params = {
                link: this.$route.path.split("/info/")[1],
                desc: transformTitleToLink(this.cardData.title),
                id: this.cardData.id,
                
            };

            const searchParams = new URLSearchParams(window.location.search);
            const langParam = searchParams.get('language');

            const query = this.shouldHideLinks ? { hideLinks: 'yes'} : {};

            if (langParam) {
                query.language = langParam;
            }

            this.$router.push({ name: 'DescView', params, query });
        },
        stripHtmlTags(htmlString) {
            const doc = new DOMParser().parseFromString(htmlString, 'text/html');
            return doc.body.textContent || "";
        },
        decodeHtmlEntities(htmlString) {
            const intermediateDecoding = document.createElement('div');
            intermediateDecoding.innerHTML = htmlString;
            const partiallyDecodedString = intermediateDecoding.innerText || intermediateDecoding.textContent || '';
            
            const finalDecoding = document.createElement('div');
            finalDecoding.innerHTML = partiallyDecodedString;
            
            return finalDecoding.innerText || finalDecoding.textContent || '';
        }
    },
    computed: {
        getTitle() {
            if (!("title" in this.cardData)) return "";
            return setCharacterLimit(this.decodeHtmlEntities(this.cardData.title), 30);
        },
        getImage() {
            if ("images" in this.cardData && this.cardData.images) {
                return this.api + this.cardData.images;
            }
            return this.cardImage;
        },
        getDescription() {
            if (!("content" in this.cardData)) return "";
            const strippedContent = this.stripHtmlTags(this.cardData.content);
            return setCharacterLimit(strippedContent, 85);
        },
        getDateInfoOpen() {
            if (this.pathName === "beaches") {
                if (!("opening_date" in this.cardData) || this.cardData.opening_date === "") return "-";
                return setCharacterLimit(this.cardData.opening_date, 55);
            } else if (this.pathName === "agenda") {
                if (!("period_start" in this.cardData) || this.cardData.period_start === "") return this.cardData.time_start;
                return setCharacterLimit(this.cardData.period_start, 55);
            }
            return "-";
        },
        getDateInfoClose() {
            if (this.pathName === "beaches") {
                if (!("closing_date" in this.cardData) || this.cardData.closing_date === "") return "-";
                return setCharacterLimit(this.cardData.closing_date, 55);
            } else if (this.pathName === "agenda") {
                if (!("period_end" in this.cardData) || this.cardData.period_end === "") return this.cardData.time_end;
                return setCharacterLimit(this.cardData.period_end, 55);
            }
            return "-";
        },
        getLenght() {
            if (!("length" in this.cardData)|| this.cardData.length == "") return "-"
            return setCharacterLimit(this.cardData.length, 55)
        },
        getWidth() {
            if (!("width" in this.cardData) || this.cardData.width == "") return "-"
            return setCharacterLimit(this.cardData.width, 55)
        },
        getSand() {
            if (!("sand" in this.cardData)|| this.cardData.sand == "") return "-"
            return setCharacterLimit(this.cardData.sand, 55)
        },
        getPrice(){
            if (!("price" in this.cardData)|| this.cardData.price == "") return "-"
            return this.cardData.price
        },
        getServices() {
            if (!("services" in this.cardData)|| this.cardData.services == "") return "-"
            return setCharacterLimit(this.cardData.services, 15)
        },
        getLocationAddress(){
            if (!("location_address" in this.cardData)|| this.cardData.location_address == "") return "-"
            return this.decodeHtmlEntities(this.cardData.location_address)    
        },
        getCategory() {
            if (!("category" in this.cardData)) return ""
            return setCharacterLimit(this.cardData.category, 25)
        },
        shouldHideLinks() {
            return this.$route.query.hideLinks === 'yes';
        },
    }
}
</script>
<style scoped>
.card-component {
    height: 250px;
    width: 600px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid rgb(128, 128, 128, 0.4);
}

.card-component__content--date{
    display: flex;
    justify-content: left;
    align-items:center;
}

.card-component__image {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    height: 100%;
    /* background-image: url("https://lloretdemar.org/wp-content/uploads/2020/03/Americanos00014.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 35% bottom 45%;
    
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(182, 182, 182);
}
.card-component__no_image {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    height: 100%;
    background-image: url("../assets/no-image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right 35% bottom 45%;
    
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(182, 182, 182); */
}
.card-component__content{
    padding: 3%;
    width: 440px;
}
.card-component__content--title{
    /* padding-bottom: 5%; */
    margin-top: 0%;
        width: 110%;
}
.card-component__content--description{
    margin-bottom: 0.5%;
    color: #696868;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-component__content--category{
    font-weight: bold;
    font-size: 80%;
    text-transform: uppercase;
    width: 110%;
    color: #6C757D;
}
.more-icon {
    background-color: #fbbf00;
    height: 40px;
    width: 80px;
    position: relative;
    bottom: -80px;
    right: -10px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1550px) {
    .card-component__content--title{
        font-size: 130%;
        width: 120%;
    }
    .card-component__content--description{
        font-size: 95%;
        width: 120%;
    }
    .card-component__content--category{
        font-size: 80%;
        width: 120%;
    }
    .more-icon {
        height: 30px;
        width: 80px;
        bottom: -50px;
        right: -5px;
    }
}
@media (max-width: 1550px) {
    .card-component__content--title{
        font-size: 100%;
    }
    .card-component__content--description{
        font-size: 80%;
    }
    .card-component__content--category{
        font-size: 70%;
    }
}

@media (max-width: 1330px) {
  .card-component{
    width: 100% !important;
  }
  .card-component__content{
    width: 100% !important;
  }
}
@media (max-width: 940px) {
    .card-component {
        height: 330px !important;
        width: 100%;
    }
}
@media (max-width: 740px) {
    .card-component {
        height: 100%;
        width: 100%;
    }
    .card-component__content{
        padding: 5%;
    }
    .card-component__content--title{
        font-size: 120%;
        margin-top: 5%;
    }
    .card-component__content--description{
        font-size: 100%;
        margin-bottom: -4%;
        font-size: 13px;
    }
    .card-component__content--category{
        padding-top: 0%;
        margin-bottom: 5%;
    }
}
@media (max-width: 420px) {
    /* .card-component {
        height: 150px !important;
    } */
    .card-component__content--date{
        font-size: 90%;
    }
    .card-component__content--title{
        font-size: 85% !important;
        margin-top: -2%;
    }
    .card-component__content--description{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-component__content--category{
        margin-top: -3%;
    }
}
@media (max-width: 380px) {
    .card-component {
        height: 200px !important;
        width: 320px;
    }
    .card-component__content{
        padding: 2%;
    }
    .card-component__content--title{
        font-size: 100%;
        margin-top: -2%;
    }
    .card-component__content--description{
        font-size: 80%;
    }
    .card-component__content--category{
        margin-top: -10%;
    }
}
</style>