import axios from "axios";
import { errorServiceManagement } from "../utils/utils";
import ServiceEndpoints from "./serviceEndpoints";

// Mapa de categorías padre a categorías hijas
const categoryMapCat = {
    572: [593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611],
    573: [],
    575: [],
    593: [596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611],
    594: [],
    595: [],
    596: [],
    597: [],
    598: [],
    599: [],
    600: [],
    601: [],
    602: [],
    603: [],
    604: [],
    605: [],
    606: [],
    607: [],
    608: [],
    609: [],
    610: [],
    611: []
};

const categoryMapEsp = {
    616: [],
    617: [],
    629: [],
    630: []
}

const categoryMapEn = {
    640: [],
    641: [],
    642: [],
    643: []
}

const categoryMapFr = {
    675: [],
    676: [],
    677: [],
    678: []
}

const categoryMapRu = {
    696: [],
    697: [],
    698: [],
    699: []
}

const categoryMapDe = {
    700: [],
    701: [],
    702: [],
    705: []
}

async function fetchAllPages(lang, title_filter, category) {
    let allData = [];
    let currentPage = 0;
    let totalPages = 1;

    while (currentPage < totalPages) {
        const result = await ServiceEndpoints.listOfContent("activity", lang, currentPage, title_filter, category);
        allData = allData.concat(result.data.rows);

        totalPages = result.data.pager.total_pages;
        currentPage++;
    }

    return allData;
}

export async function getActivities(lang, page, title_filter, category_filter) {
    const regex = /<img.*?src=['"](.*?)['"]/;
    let allActivities = [];

    let categoryMap;

    switch (lang) {
        case 'ca':
            categoryMap = categoryMapCat;
            break;
        case 'es':
            categoryMap = categoryMapEsp;
            break;
        case 'en':
            categoryMap = categoryMapEn;
            break;
        case 'fr':
            categoryMap = categoryMapFr;
            break;
        case 'ru':
            categoryMap = categoryMapRu;
            break;
        case 'de':
            categoryMap = categoryMapDe;
            break;
        default:
            categoryMap = {};
    }

    // Obtener las categorías hijas
    const childCategories = categoryMap[category_filter] || [];
    const categories = [category_filter, ...childCategories];

    // Recoger datos de todas las categorías y combinarlos
    for (const category of categories) {
        const categoryData = await fetchAllPages(lang, title_filter, category);
        allActivities = allActivities.concat(categoryData);
    }

    // Eliminar duplicados basados en el ID del nodo (nid)
    const uniqueActivities = [...new Map(allActivities.map(item => [item.nid, item])).values()];

    // Organizar los resultados en la estructura necesaria
    let testCards = [];
    uniqueActivities.forEach(element => {
        let testElement = {
            id: element.nid ? element.nid : "",
            title: element.title ? element.title : "",
            content: element.content ? element.content : "",
            images: element.images.length > 0 ? regex.exec(element.images)[1] : "",
            location_latitude: element.location_latitude ? element.location_latitude : "",
            location_longitude: element.location_longitude ? element.location_longitude : "",
            created: element.created ? element.created : "",
            category: element.category ? element.category : "",
            pager: {
                current_page: page,
                total_items: uniqueActivities.length,
                total_pages: Math.ceil(uniqueActivities.length / 10),
                items_per_page: 10
            }
        }
        testCards.push(testElement);
    });

    // Paginación manual de los resultados combinados
    const itemsPerPage = 10; // Asumiendo que son 10 ítems por página
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = testCards.slice(start, end);

    return paginatedData;
}

export function getActivitiesbySearch(search) {
    return axios.get(`${URL}/mockend/demo/posts?title_contains=${search}`)
        .then(response => response)
        .catch(error => errorServiceManagement(error));
}

export function getActivitiesbyCategory(category) {
    return axios.get(`${URL}/mockend/demo/posts?category_contains=${category}`)
        .then(response => response)
        .catch(error => errorServiceManagement(error));
}
