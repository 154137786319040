import { getTextFromATag } from "../utils/utils";
import ServiceEndpoints from "./serviceEndpoints";

export async function getCulture(lang, page, title_filter,config) {
    const regex = /<img.*?src=['"](.*?)['"]/;
    let testCards = []
    await ServiceEndpoints.listOfContent("culture", lang, page, title_filter,config).then((result) => {
        result.data.rows.forEach(element => {
            let testElement = { 
                id: element.nid ? element.nid : "",
                title: element.title ? element.title : "",
                content: element.content ? getTextFromATag(element.content) : "",
                images: element.images.length > 0 ? regex.exec(element.images)[1] : "",
                location_address: element.location_address ? element.location_address : "",
                location_latitude: element.location_latitude ? element.location_latitude : "",
                location_longitude: element.location_longitude ? element.location_longitude : "",
                pager: result.data.pager
            }
            testCards.push(testElement)
        })

        return testCards
    }).catch((error) => {
        throw error
    });
    return testCards
}
